import React from "react"
import PropTypes from "prop-types"
import Styled from "styled-components"
import {Code} from "../Plugin"
import {SectionWrapper, Col} from "@styles/grid"
import {navigateTo} from "@utils"
import {addContact} from "@utils/apiCall"
import {Heading2, BodyText, Heading4} from "@styles/text"
import {TextInput} from "@styles/inputs"
import {PrimaryButton} from "@styles/buttons"
import {backgrounds} from "@constants/colors"

const PricingGateWrapper = Styled.div`
  height: 100vh;

  & .col-12 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & .heading {
    margin-bottom: 20px;
  }

  & .body {
    margin-bottom: 40px;
  }

  & form {
    width: 100%;
    max-width: 770px;
    height: 215px;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
    background-color: #ffffff;

    padding: 24px;
    margin: 0px auto;
    margin-bottom: 80px;
  }

  & .uprise-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & img {
      width: 24px;
    }
  }

  @media (min-width: 1024px) {
    & form {
      margin-bottom: 120px;
      padding: 40px;
    }
  }
`

function PricingGate({heading, subHeading, buttonLabel, redirectTo, sendInBlueListId, plugins}) {
	const [email, setEmail] = React.useState("")
	const [token, setToken] = React.useState(false)
	const key = `upriseEmail-${redirectTo}`
	React.useEffect(() => {
		const token = localStorage.getItem(key)
		setToken(token)
	}, [key, setToken])

	if (token) {
		return null
	}

	return (
		<SectionWrapper
			containerFluidProps={{
				style: {
					backgroundColor: backgrounds.fadedPurple,
					position: "fixed",
					top: 0,
					zIndex: 10
				}
			}}>
			<PricingGateWrapper className='row'>
				<Col>
					<Heading2 textCenter className='heading'>
						{heading}
					</Heading2>
					<BodyText textCenter className='body'>
						{subHeading}
					</BodyText>

					<form
						onSubmit={async event => {
							event.preventDefault()
							try {
								localStorage.setItem(key, "saved")
								const listIds = sendInBlueListId.split(",").map(i => i * 1)
								await addContact({
									email,
									listIds
								})
								setToken("saved")
								navigateTo(redirectTo)
							} catch (error) {
								if (error.response.data.code === "duplicate_parameter") {
									setToken("saved")
									navigateTo(redirectTo)
								}
								console.error({error})
							}
						}}>
						<TextInput
							onChange={({target: {value}}) => setEmail(value)}
							value={email}
							id='email'
							label='Email Address'
							isRequired
							type='email'
						/>
						<PrimaryButton large>{buttonLabel}</PrimaryButton>
					</form>

					<div className='uprise-logo'>
						<img src={require("../../images/logo.webp")} alt='uprise logo' width='106' height='30' />
						<Heading4 color='#35359c'>Uprise</Heading4>
					</div>
				</Col>
			</PricingGateWrapper>
			{plugins && plugins.length && <Code blok={plugins.find(p => p.component === "CODE")} />}
		</SectionWrapper>
	)
}

export default PricingGate

PricingGate.propTypes = {
	heading: PropTypes.string.isRequired,
	subHeading: PropTypes.string.isRequired,
	buttonLabel: PropTypes.string.isRequired,
	redirectTo: PropTypes.string.isRequired,
	plugins: PropTypes.array
}
PricingGate.defaultProps = {
	heading: `To see pricing details, please enter your email below`,
	subHeading: `We will not spam your email address`,
	buttonLabel: `See pricing`,
	redirectTo: "/pricing"
}
